import {createBrowserRouter} from "react-router-dom";

import Layout from "../_Layout"
import ErrorPage from "../pages/404";

import Home from "../pages/home";
import Attente from "../pages/attente";
import MentionsLegales from "../pages/mentions-legales";
import Faq from "../pages/faq";
import JeParticipe from "../pages/je-participe";
import OperationTerminee from "../pages/operation-terminee";
import CoinCatcher from "../game/coin_catcher/CoinCatcher";
import {isOpeInProgressRedirect, isOpeStartRedirect, isOpeStopRedirect} from "../loader/redirectLoader";
import ProduitsEligibles from "../pages/produits-eligibles";
import Recettes from "../pages/recette";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children : [
            {
                path: "/",
                element : <Home/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/attente",
                element : <Attente/>,
                //loader : isOpeInProgressRedirect
            },
            {
                path : "/informations-legales",
                element : <MentionsLegales/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/faq",
                element : <Faq/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/je-participe",
                element : <JeParticipe/>,
                loader : isOpeStopRedirect
            },
            {
                path : "/operation-terminee",
                element : <OperationTerminee/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/produits-eligibles",
                element : <ProduitsEligibles/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/jeu",
                element : <CoinCatcher/>
            },
            {
                path : "/recettes",
                element : <Recettes/>
            }
        ]
    },
]);

export default router