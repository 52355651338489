import React from 'react';
import PageTitle from "./_title";

const Recettes = () => {

    return (
        <>
            <PageTitle/>

            <div id={"recetteStrate1"} className={"strate first"}>
                <div className={"cta_wrapper"}>
                    <a href={"#team_sucree"}>Découvrir<br className={"d-lg-none"}/> notre recette</a>
                    <a href={"#team_salee"}>Découvrir<br className={"d-lg-none"}/> notre recette</a>
                </div>
            </div>

            <div id={"recetteStrate2"} className={"strate strate_recipe"}>
                <div className={"anchor"} id={"team_sucree"}/>

                <iframe className={"recipe_video"} src="https://youtube.com/embed/UjMlb_OBq74?si=M8_7yTfThf5HgSqv"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>

                <div className={"title"}>Crêpes au beurre noisette,<br className={"d-lg-none"}/> caramel<br
                    className={"d-none d-lg-block"}/> beurre salé et noix<br className={"d-lg-none"}/> de pécan
                    sablées <span>(environ 10 crêpes)</span></div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>1. Pâte à crêpe au beurre noisette :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>
                            <ul>
                                <li><b>60g</b> de beurre doux « Nature de Breton »</li>
                                <li><b>500g</b> de lait frais « Marguerite »</li>
                                <li><b>2</b> œufs entiers</li>
                                <li><b>200g</b> de farine de blé T55</li>
                                <li><b>30g</b> de sucre semoule</li>
                                <li><b>20g</b> (de rhum*)</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>

                            <p>
                                <span>1</span>
                                Faites chauffer le beurre dans une casserole. Lorsqu’il est fondu, continuez la cuisson
                                jusqu’à ce que le beurre blondisse en fouettant sans arrêt. Versez aussitôt dans un
                                saladier en verre ou en inox et laissez tiédir.
                            </p>
                            <p>
                                <span>2</span>
                                Mélangez au fouet le lait et les œufs. Versez dans un autre saladier le sucre et la
                                farine.
                            </p>
                            <p>
                                <span>3</span>
                                Ajoutez petit à petit le mélange liquide en fouettant au fur et à mesure, pour ne pas
                                faire de grumeaux.
                            </p>
                            <p>
                                <span>4</span>
                                Ajoutez le beurre noisette encore liquide et le rhum si vous le souhaitez. Laissez
                                reposer au frais au moins 1 heure.
                            </p>

                        </div>
                        <div className={"recipe_mention"}>*L’abus d’alcool est dangereux pour la santé, à consommer avec
                            modération.
                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>2. Sauce caramel beurre salé :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>
                            <ul>
                                <li><b>25g</b> d’eau</li>
                                <li><b>150g</b> de sucre semoule</li>
                                <li><b>90g</b> de crème liquide</li>
                                <li><b>35g</b> de beurre salé « Nature de Breton »</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>

                            <p>
                                <span>1</span>
                                Faites bouillir la crème et réservez.
                            </p>
                            <p>
                                <span>2</span>
                                Chauffez l’eau et le sucre jusqu’à ce qu’ils deviennent caramel.
                            </p>
                            <p>
                                <span>3</span>
                                Faites décuire en ajoutant le beurre salé tout en fouettant, puis la crème encore
                                chaude. Réchauffez légèrement en fouettant pour obtenir une sauce lisse.
                            </p>
                            <p>
                                <span>4</span>
                                Laissez refroidir au réfrigérateur.
                            </p>

                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>3. Noix de pécans sablées :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>
                            <ul>
                                <li><b>25g</b> d’eau</li>
                                <li><b>50g</b> de sucre semoule</li>
                                <li><b>10g</b> de miel</li>
                                <li><b>100g</b> de noix de pécan</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>
                            <p>
                                <span>1</span>
                                Mettez l’eau et le sucre dans la casserole. Faites bouillir en veillant à ce que le
                                sucre soit bien fondu.
                            </p>
                            <p>
                                <span>2</span>
                                Ajoutez le miel hors du feu et versez sur les noix de pécan. Répartissez-les sur une
                                plaque de four avec un papier sulfurisé et faites-les colorer environ 10 minutes à 180
                                degrés.
                            </p>
                            <p>
                                <span>3</span>
                                Sortez-les en les remuant un peu pour les décoller. Laissez-les refroidir.
                            </p>

                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>4. Cuisson et dressage :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"recipe_step full"}>
                            <p>
                                <span>1</span>
                                Faites chauffer une poêle antiadhésive avec un peu d’huile.
                            </p>
                            <p>
                                <span>2</span>
                                Mélangez bien la pâte à crêpe puis versez une louche et cuire quelques minutes de chaque
                                côté.
                            </p>
                            <p>
                                <span>3</span>
                                Placez-les dans une assiette. Etalez du caramel au beurre salé sur chaque crêpe, puis
                                roulez-les joliment.
                            </p>
                            <p>
                                <span>4</span>
                                Placez 2 ou 3 rouleaux sur une assiette, lustrez avec un peu de sauce et parsemez de
                                noix de pécan sablées.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={"recipe_milk"}/>

            </div>

            <div id={"recetteStrate3"} className={"strate strate_recipe"}>
                <div className={"anchor"} id={"team_salee"}/>

                <iframe className={"recipe_video"} src="https://www.youtube.com/embed/2oXjHROmZ78?si=P4w20nJdSwuZQ9LK"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>

                <div className={"title"}>Crêpes au sarrasin, beurre <br className={"d-lg-none"}/>d’agrumes,<br
                    className={"d-none d-lg-block"}/> truite fumée et<br className={"d-lg-none"}/> carottes
                    confites<span>(environ 10 crêpes)</span></div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>1. Pâte à crêpe au sarrasin :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>

                            <ul>
                                <li><b>250g</b> de lait frais « GrandLait »</li>
                                <li><b>250g</b> d’eau</li>
                                <li><b>1</b> œuf entier</li>
                                <li><b>250g</b> de farine de sarrasin</li>
                                <li><b>30g</b> de farine de blé T55</li>
                                <li><b>1</b> cuillère à café de sel fin</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>

                            <p>
                                <span>1</span>
                                Mélangez au fouet le lait, l’eau et l’œuf puis versez dans un autre saladier le sel et
                                les farines.
                            </p>
                            <p>
                                <span>2</span>
                                Ajoutez petit à petit le mélange liquide pour ne pas faire de grumeaux.
                            </p>
                            <p>
                                <span>3</span>
                                Laissez reposer au frais.
                            </p>

                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>2. Beurre d’agrumes :</div>
                    <div className={"recipe_wrapper"}>
                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>

                            <ul>
                                <li><b>250g</b> de beurre doux « Nature de Breton »</li>
                                <li><b>2</b> oranges</li>
                                <li><b>1</b> citron jaune</li>
                                <li><b>1</b> cuillère à café de sel fin</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>

                            <p>
                                <span>1</span>
                                Sortez le beurre à l’avance ou mettez-le quelques minutes au micro-onde en décongélation
                                (le beurre doit être pommade).
                            </p>
                            <p>
                                <span>2</span>
                                Zestez finement les agrumes dans le beurre pommade et mélangez avec le sel.
                            </p>
                            <p>
                                <span>3</span>
                                Pressez le jus des agrumes et réservez au frais.
                            </p>

                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>3. Carottes confites :</div>
                    <div className={"recipe_wrapper"}>

                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>
                            <ul>
                                <li><b>3</b> carottes multicolores (si possible)</li>
                                <li><b>50g</b> de beurre ½ sel « Nature de Breton »</li>
                                <li>Le jus des agrumes</li>
                                <li><b>1</b> pincée de poivre moulu</li>
                            </ul>
                        </div>
                        <div className={"recipe_step"}>
                            <p>
                                <span>1</span>
                                Lavez et épluchez les carottes. Faites des lamelles dans le sens de la longueur avec un
                                économe.
                            </p>
                            <p>
                                <span>2</span>
                                Faites fondre le beurre et versez sur les lamelles de carottes pour les enrober avec un
                                peu de poivre et un peu de jus des agrumes.
                            </p>
                            <p>
                                <span>3</span>
                                Enfournez 5 minutes à 200°C et laissez reposer.
                            </p>

                        </div>
                    </div>
                </div>

                <div className={"recipe_block"}>
                    <div className={"block_title"}>4. Cuisson et dressage :</div>
                    <div className={"recipe_wrapper"}>

                        <div className={"ingredients"}>
                            <div className={"ingredient_title"}>Ingrédients</div>
                            <ul>


                                <li>Quelques pousses d’épinards</li>
                                <li><b>500g</b> de truite fumée</li>
                            </ul>
                        </div>

                        <div className={"recipe_step"}>
                            <p>
                                <span>1</span>
                                Faites chauffer une poêle antiadhésive avec un peu d’huile.
                            </p>
                            <p>
                                <span>2</span>
                                Mélangez bien la pâte à crêpe puis versez une louche et cuire quelques minutes de chaque
                                côté.
                            </p>
                            <p>
                                <span>3</span>
                                Placez-les dans une assiette. Étalez le beurre d’agrumes finement sur les crêpes,
                                ajoutez des tranches de truites fumées puis roulez les crêpes en serrant bien.
                            </p>
                            <p>
                                <span>4</span>
                                Placez dans l’assiette. Disposez les lamelles de carottes confites et les pousses
                                d’épinards.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={"recipe_milk"}/>

            </div>

        </>
    );
};

export default Recettes;
